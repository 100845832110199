.loading_spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: rgba(88, 86, 86, 0.25);
    z-index: 1000001;

    .percentage_loading {
        position: absolute;
        justify-content: center;
        align-items: center;
        font-size: large;
        font-weight: 700;
        color: rgb(0, 120, 212);
    }
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: rgb(0, 120, 212);
            margin: -4px 0 0 -4px;
        }
    }
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.instagram_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
}

.lds-instagram {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-instagram div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #ccc;
    animation: lds-instagram 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-instagram div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-instagram div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-instagram div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-instagram {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}
